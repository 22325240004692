@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@font-face {
  font-family: 'SF Pro Text';
  src: url('../public/fonts/SF-Pro-Text-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('../public/fonts/SF-Pro-Text-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('../public/fonts/SF-Pro-Text-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('../public/fonts/SF-Pro-Text-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('../public/fonts/SF-Pro-Text-RegularItalic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('../public/fonts/SF-Pro-Text-Heavy.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}


@font-face {
  font-family: 'Share Tech Mono';
  src: url('../public/fonts/ShareTechMono-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
/* Add other font styles as needed */
